import request from '@/utils/request'

//支付金额统计
export function getPayCount(params) {
    return request({
        url: '/admin/indexReport/payCount',
        method: 'GET',
        params
    })
}
//商品销售排行
export function getRankingGoods(params) {
    return request({
        url: '/admin/indexReport/rankingGoods',
        method: 'GET',
        params
    })
}