<template>
  <div class="index">
    <div class="title" style="margin-top:0 !important">
      <span
        style="font-size:1vw;border-bottom:0.2vw solid #1684FC;margin-right:1vw;font-weight: bold;"
        >实时概况</span
      ><span style="font-size:0.5vw;">更新时间:  {{date}} 00:00:00</span>
    </div>
    <div class="row top-content">
      <div class="left-content clm">
        <span style="font-size:0.5vw;font-weight: 530;">支付金额(元)</span>
        <span style="font-size:1vw;margin:0.1vw 0">{{ todayAmount }}</span>
        <span style="font-size:0.5vw;color:#C2C2C2"
          >昨天全天: {{ yesterdayAmount }}</span
        >
        <div class="echart" id="mychart" :style="myChartStyle"></div>
      </div>
      <div class="right-content gird">
        <div class="item clm alc" v-for="item in list" :key="item.value">
          <span>{{ item.name }}</span>
          <span style="margin:0.3vw 0">{{ item.today }}</span>
          <span class="cr-c2">昨天全天:{{ item.yesterday }}</span>
        </div>
      </div>
    </div>
    <div class="title">
      <span
        style="font-size:1vw;border-bottom:0.2vw solid #1684FC;margin-right:1vw;font-weight: bold;"
        >商品看板</span
      ><span style="font-size:0.5vw;">更新时间:  {{date}} 00:00:00</span>
    </div>
    <div class="row top-content">
      <div class="left-content clm table">
        <div style="margin-bottom:1vw">访客榜</div>
        <el-table
        border
          :data="viewRank"
          style="width: 100%;"
          size="mini"
          height="40vh"
          :header-cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column type="index" label="排名" width="50" align="center">
          </el-table-column>
          <el-table-column
            prop="goods_name"
            label="商品"
            width="auto"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="view_count"
            label="访客数"
            width="auto"
            align="center"
          >
          </el-table-column>
        </el-table>
      </div>
      <div class="right-content clm table">
        <div style="margin-bottom:1vw">销售榜单</div>
        <el-table
        border
          :data="saleRank"
          style="width: 100%;"
          size="mini"
		  height="40vh"
          :header-cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column type="index" label="排名" width="50" align="center">
          </el-table-column>
          <el-table-column
            prop="goods_name"
            label="商品"
            width="auto"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="sale_amount"
            label="支付件数"
            width="auto"
            align="center"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div style="height:4vh"></div>
    <div class="title">
      <span
        style="font-size:1vw;border-bottom:0.2vw solid #1684FC;margin-right:1vw;font-weight: bold;"
        >推广数据</span
      ><span style="font-size:0.5vw;">更新时间: {{date}} 00:00:00</span>
    </div>
    <div class="row top-content">
      <div class="left-content gird">
        <div class="item clm alc" v-for="item in list1" :key="item.value">
          <span>{{ item.name }}</span>
          <span style="margin:0.3vw 0">{{ item.today }}</span>
          <span class="cr-c2">昨天全天:{{ item.yesterday }}</span>
        </div>
      </div>
      <div class="right-content clm table">
        <div style="margin-bottom:1vw">销售榜单</div>
        <el-table
          :data="spreadRank"
          style="width: 100%;"
          size="mini"
          height="40vh"
          border
          :header-cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column type="index" label="排名" width="50" align="center">
          </el-table-column>
          <el-table-column
            prop="goods_name"
            label="商品"
            width="auto"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="sale_amount"
            label="支付件数"
            width="auto"
            align="center"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div style="height:4vh"></div>
  </div>
</template>

<script>
import { getPayCount, getRankingGoods } from "@/newApi/index";
import * as echarts from "echarts";
export default {
  data() {
    return {
      todayAmount: 0, //今日支付数据
      yesterdayAmount: 0, //昨日支付数据
      info: {}, //支付数据
      list: [],
      weekData: [], //支付数据近七天
      viewRank: [], //访客榜
      saleRank: [], //销售榜
      spreadRank: [], //推广榜
      info1: {}, //数据
      list1: [],
      myChartStyle: { float: "left", width: "40vw", height: "40vh" }, //图表样式
      xData: [],
      yData: [],
      date:''
    };
  },
  async created() {
    await this._getRankingGoods();
    await this._getPayCount();
    //获取当前时间年月日,2022-05-08组装
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    this.date = year + "-" + month + "-" + day;
    console.log(2222);
  },
  mounted() {
    setTimeout(() => {
      this.initEcharts();
    }, 1000);
  },
  methods: {
    async _getPayCount() {
      let res = await getPayCount();
      console.log(res);
      this.weekData = res.data.weekData;
      this.info = res.data.info;
      this.yesterdayAmount = res.data.yesterdayAmount;
      this.todayAmount = res.data.todayAmount;
      res.data.weekData.forEach((item) => {
        this.xData.push(item.day + "日");
        this.yData.push(item.amount);
      });
      console.log(this.xData);
      this.list = [
        {
          value: 1,
          name: "支付人数",
          today: res.data.info.today_pay_user,
          yesterday: res.data.info.yesterday_pay_user,
        },
        {
          value: 2,
          name: "支付订单数",
          today: res.data.info.today_pay_order,
          yesterday: res.data.info.yesterday_pay_order,
        },
        {
          value: 3,
          name: "成功退款金额(元)",
          today: res.data.info.today_refund_amount,
          yesterday: res.data.info.yesterday_refund_amount,
        },
        {
          value: 4,
          name: "客单价(元)",
          today: res.data.info.today_avg,
          yesterday: res.data.info.yesterday_avg,
        },
        {
          value: 5,
          name: "访客数",
          today: res.data.info.today_visitor_count,
          yesterday: res.data.info.yesterday_visitor_count,
        },
        {
          value: 6,
          name: "浏览数",
          today: res.data.info.today_view_count,
          yesterday: res.data.info.yesterday_view_count,
        },
      ];
    },
    async _getRankingGoods() {
      let res = await getRankingGoods();
      this.viewRank = res.data.viewRank;
      this.saleRank = res.data.saleRank;
      this.spreadRank = res.data.spreadRank;
      this.info1 = res.data.info;
      this.list1 = [
        {
          value: 1,
          name: "支付金额(元)",
          today: res.data.info.today_pay_amount,
          yesterday: res.data.info.yesterday_pay_amount,
        },
        {
          value: 2,
          name: "成交客户数",
          today: res.data.info.today_use_count,
          yesterday: res.data.info.yesterday_use_count,
        },
        {
          value: 3,
          name: "成交商品数",
          today: res.data.info.today_goods_count,
          yesterday: res.data.info.yesterday_goods_count,
        },
        {
          value: 4,
          name: "支出推广费(元)",
          today: res.data.info.today_spread_amount,
          yesterday: res.data.info.yesterday_spread_amount,
        },
        {
          value: 5,
          name: "分享总次数",
          today: res.data.info.today_share_count,
          yesterday: res.data.info.yesterday_share_count,
        },
        {
          value: 6,
          name: "访问总人数",
          today: res.data.info.today_visitor_count,
          yesterday: res.data.info.yesterday_visitor_count,
        },
      ];
      console.log(res);
    },
    initEcharts() {
      // 基本柱状图
      const option = {
        magicType: {
          //动态类型切换
          type: ["bar", "line"],
        },
        tooltip: {
          // 提示框组件。// trigger:'item' 默认的鼠标移动到色块上触发
          trigger: "axis", // 鼠标只要在轴上就会触发
          axisPointer: {
            // 坐标轴指示器配置项。
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        xAxis: {
          data: this.xData,
        },
        yAxis: {},
        series: [
          {
            type: "bar", //形状为柱状图
            data: this.yData,
            itemStyle: {
              color: "rgba(80,135,236)",
            },
          },
        ],
      };
      const myChart = echarts.init(document.getElementById("mychart"));
      myChart.setOption(option);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.index {
  height: auto;
  background-color: white;
}
.title {
  width: 93vw;
  padding: 0.2vw 1vw;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  border-radius: 0.1vw;
  background-color: #efefef;
  margin: 1vw 0;
}
.top-content {
  width: 93vw;
  height: 40vh;
  background-color: #fff;
  border-radius: 0.1vw;
  box-sizing: border-box;
  padding: 1vw;
  justify-content: space-between;
  .right-content {
    width: 42vw;
    height: 40vh;
    margin-right: 5vw;
  }
  .gird {
    display: grid;
    //两行三列
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    //行列间距
    grid-row-gap: 1vw;
    grid-column-gap: 1vw;
  }
  .left-content {
    width: 42vw;
    height: 40vh;
    // background-color: rgb(21, 141, 27);
  }
}
.table {
  padding: 1vw;
  box-sizing: border-box;
  border-radius: 0.2vw;
  background-color: #efefef;
}
.clm {
  display: flex;
  flex-direction: column;
}
.row {
  display: flex;
}
.alc {
  align-items: center;
}
.jsb {
  justify-content: space-between;
}
.cr-c2 {
  color: #c2c2c2;
}
</style>
